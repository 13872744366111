// FONTS
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,400&display=swap')
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,700;1,300&family=Roboto:ital,wght@0,300;0,400;0,700;1,400&display=swap')

@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600&display=swap');

// CONSTANS
$bgcolor: #F0F2F7;

html {
  background-color: $bgcolor; }

body {
  margin: 0;
  font-family: 'Barlow', sans-serif;
  letter-spacing: 1px; }
h1,h2,h3,h4,h5,h6 {
  font-family: 'Barlow', sans-serif;
  font-weight: 700;
  letter-spacing: 2px; }
h2 {
  font-size: 46px;
  color: #1767B1; }
label,
span,
a {
  font-family: 'Barlow', sans-serif;
  font-weight: 400;
  font-size: 18px; }

button {
  font-family: 'Barlow', sans-serif;
  font-weight: 700 !important;
  font-size: 25px !important; }
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

// WRAPPER
.wrapper {
  background-color: $bgcolor;
  height: 100vh;
  // HEADER
  header {
    padding-top: 15px;
    padding-left: 35px;
    margin-bottom: 10px;
    // LOGO
    .logo {
      img {
        width: 109px;
        height: 78px; } } } }
// FORM
.form__btn {
  background-color: #1666B0;
  border-color: #1666B0;
  margin-top: 30px;
  min-width: 201px;
  max-width: 201px;
  width: 100%;
  padding: 10px; }
// LOGIN FORM
.form__box {
  .invalid-feedback {
    display: block; }
  .login__form,
  .registration__form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h2 {
      margin-bottom: 23px; }
    input {
      max-width: 348px;
      padding: 15px; }
    .inputs-box {
      input {
        height: 85px; } }
    .form-group {
      margin-bottom: 40px; }
    .btnbox {
      display: flex;
      flex-direction: column;
      align-items: center; }
    /* Forgot Password? */
    .forgot_pass_link {
      font-size: 20px;
      color: #474DDC;
      text-decoration: none; } }
  .text__under__form {
    max-width: 348px;
    margin-top: 25px;
    text-align: center;
    a {
      color: #474DDC;
      text-decoration: none; } } }

.recover {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &__header {
    h2 {
      margin-bottom: 38px; }
    p {
      font-size: 24px;
      max-width: 350px; } }
  &__email,
  &__text,
  &__sms {}
  &__dawnload {
    span {
      display: block;
      max-width: 300px;
      margin-top: 20px;
      margin-bottom: 30px; } }
  &__btn {
    display: block;
    background-color: #1666B0;
    border-color: #1666B0;
    min-width: 348px;
    padding: 10px; }
  &__email {
    span {
      display: block;
      max-width: 300px;
      margin-top: 20px;
      margin-bottom: 30px;
      margin-left: 20px; } }
  &__text {
    span {
      text-align: center;
      margin-bottom: 0px; }
    button {
      margin-bottom: 36px; } }
  &__sms {
    span {
      margin-left: 15px;
      margin-bottom: 0px; }
    &.marginblabel {
      span {
        margin-left: 0;
        margin-bottom: 20px; } } }
  &__dawnload {
    span {
      margin-bottom: 15px;
      margin-top: 20px; }
    h4 {
      position: relative;
      max-width: 342px;
      .img--icon {
        position: absolute;
        right: -17px;
        top: 15px; } } }
  input,
  select {
    margin-bottom: 53px;
    max-width: 348px;
    padding: 15px; } }
.registration__form {
  .flexgroup {
    display: flex;
    .form-group {
      margin-right: 15px;
      input {
        max-width: 168px; } } } }
.mb40 {
  margin-bottom: 31px; }
.with--arrow {
  position: relative;
  svg {
    position: absolute;
    left: -62px;
    top: 14px;
    cursor: pointer;
    &:hover {
      fill: #5082B6; } } }
.mb0 {
  margin-bottom: 0 !important; }
.formcheck {
  margin-top: 12px;
  label {
    margin-left: 15px;
    font-size: 12px;
    max-width: 350px; } }

$primary-color: #1666B0;

.dots {
  margin: 40px auto; }

.dot {
  display: inline-block;
  border-radius: 50%;
  width: 50%;
  background-color: $primary-color;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  border: 1px solid #000;
  // cursor: pointer

  &.dot-success {
    background-color: $primary-color; }

  &.dot-warning {
    background-color: $primary-color; }

  &.dot-danger {
    background-color: $primary-color; }

  &.dot-white {
    background-color: #fff; }

  &.dot-sm {
    height: 29px;
    width: 29px; }

  &.dot-lg {
    margin: 0 12px;
    height: 29px;
    width: 29px; } }

.center {
  text-align: center; }
.withdots {
  margin-top: 82px; }
input::-webkit-input-placeholder {
  font-size: 12px; }

.recover__text span {
  max-width: 348px;
  display: block; }

.formcheck {
  a {
    font-size: 12px !important;
    text-decoration: none; }
  label {
    max-width: 326px; } }
.invalid-feedback {
  display: block; }

span.red {
  display: block;
  color: red;
  margin-top: 32px; }
span.green {
  display: block;
  color: green;
  margin-top: 32px; }

.btn-primary:hover {
  background-color: darken(#1666B0, 10%);
  border-color: darken(#1666B0, 10%); }
.btn-check:focus+.btn-primary, .btn-primary:focus {
  background-color: darken(#1666B0, 10%);
  border-color: darken(#1666B0, 10%);
  box-shadow: none; }
.btn-primary.disabled, .btn-primary:disabled {
  background-color: darken(#1666B0, 5%);
  border-color: darken(#1666B0, 5%); }
.form-control.is-invalid, .was-validated .form-control:invalid {
  background-image: none; }
form.recover__text input {
  margin-bottom: 18px; }
form.recover__text span {
  margin-bottom: 7px; }
.recover__minimize p {
  margin-bottom: 15px;
  margin-top: -15px;
  text-align: center; }
.invalid-feedback {
  position: relative;
  height: 0;
  top: -50px; }
.form-check-input:checked {
  background-color: #1666B0;
  border-color: #1666B0; }
form.registration__form .invalid-feedback {
  top: 0; }
form.login__form .form-group .invalid-feedback {
  position: absolute;
  top: auto; }
.col-lg-6.recover form.recover__text .invalid-feedback {
  top: -15px; }
.mt15 {
  margin-top: 15px; }
form.col-lg-6.recover .recover__sms select.form-select.mb0 + .invalid-feedback {
  position: absolute;
  top: auto;
  bottom: 0; }
form.col-lg-6.recover .recover__sms select.form-select.mb0 {
  margin-bottom: 15px!important; }
.col-lg-6.recover .recover__sms.marginblabel {
  position: relative; }
.recover__sms.marginblabel input.form-select.mb0.is-invalid.mobile__without__mb + .invalid-feedback {
  top: auto; }
a.btn.btn-primary.recover__btn {
  max-width: 348px; }

.with__eye {
  position: relative;
  max-width: 348px;
  .pass__eye {
    position: absolute;
    cursor: pointer;
    right: 16px;
    top: 20px; } }
.max__w {
  max-width: 348px; }
.litle__input__error {
  max-width: 168px; }
.form-select.is-invalid {
  background-image: none !important; }
.form-select {
  background-image: url('./components/selectIcon/selectIcon.png');
  background-size: 18px 18px; }
.form-select.is-invalid {
  background-image: url('./components/selectIcon/selectIcon.png') !important;
  background-size: 18px 18px !important; }

.invalid-feedback.pass_two_error {
  top: -3px; }
.form-select {
  color: #444;
  font-size: 14px; }
.form-check-input {
  border: 2px solid #000;
  background-color: transparent;
  color: transparent;
  cursor: pointer; }
.recover__sms.flex {
  display: flex;
  flex-direction: column; }

.recover__sms.marginblabel span {
  display: block;
  margin-bottom: 0.5rem; }

.form-check-input {
  width: 20px;
  height: 20px;
  -moz-appearance: auto;
  appearance: auto; }
label.form-check-label {
  font-size: 8px;
  margin-left: 5px; }
.formcheck a {
  font-size: 8px !important;
  text-decoration: none; }
.formcheck label {
  display: block;
  padding-top: 7px; }
.modal-header {
  border-bottom: none; }
.modal-content {
  padding: 15px 35px; }

.container.with__dots {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .row.login__page__row {
    min-height: 641px; } }
a.btn.btn-primary.recover__btn {
  max-width: 348px;
  font-family: 'Barlow', sans-serif;
  font-weight: 700 !important;
  font-size: 16px !important; }
select.form-select + .invalid-feedback {
  position: static; }
.select__form .recover__sms.marginblabel {
  position: relative;
  margin-bottom: 15px; }
.recover__sms.marginblabel.lastselect .invalid-feedback {
  position: relative; }
img.mobile__image {
  height: 125px;
  margin: 0 auto;
  margin-bottom: 25px;
  display: block;
  max-width: 199px;
  min-width: 199px;
  width: 199px; }
p.mobile__text {
  text-align: left !important;
  margin-top: 45px !important;
  margin-bottom: 100px !important;
  line-height: 2.4em;
  a {
    color: #212529;
    text-decoration: none; } }

.form-check-input {
  width: 5px;
  height: 5px;
  -webkit-appearance: none;
  appearance: none;
  padding: 7px !important; }

.form-control.bigger-placeholder::placeholder {
  font-size: 14px; }

input.form-control.with-form-text {
  margin-bottom: 0; }
.with__eye.with-form-text + .invalid-feedback {
  top: auto;
  position: static;
  height: auto; }
.recover__sms.marginblabel.with-margin-bottom {
  margin-bottom: 30px; }
.recover-text-left .recover__header.recover__minimize p {
  text-align: left; }
form.recover__text.recover-text-left span {
  text-align: left; }
.recover__email.to-center span {
  margin-left: 0; }

.sing_up_box {
  background: #F0F2F7;
  width: 600px;
  display: block;
  margin: 0 auto;
  text-align: center;
  padding: 40px;
  padding-bottom: 0;
  font-family: 'Barlow', sans-serif;
  h1 {
    color: #1767B1;
    font-size: 2.5em;
    font-weight: 900;
    margin-bottom: 40px; } }

.qr_box {
  padding: 0;
  margin: 40px 0;
  margin-top: 82px;

  li {
    width: 49%;
    display: inline-block;
    text-align: center;

    img {
      display: block;
      margin: 0 auto; }

    .logo_img {
      margin-bottom: 20px;
      height: 40px; } } }

.form__box .login__form,
.form__box .registration__form {
  justify-content: center;
  align-items: center;
  position: relative;
  top: -32px; }

.form__box .login__form input, .form__box .registration__form input {
  min-width: 348px;
  max-width: 348px; }

.PhoneInput {
  display: flex;
  max-width: 348px;
  align-items: center; }

.p-center {
  max-width: 348px; }

input.PhoneInputInput {
  min-width: 278px !important;
  max-width: 278px !important; }

.inputs-box {
  display: flex;
  justify-content: space-between;
  width: 590px;
  position: relative;
  top: 11px; }
.inputs-inline {
  max-width: 45px; }
.inputs-inline input {
  max-width: 85px !important;
  min-width: 45px !important; }

form.login__form.button-0 {
  height: auto;
  margin-top: 50px;
  margin-bottom: -75px; }

.ta-center {
  text-align: center !important; }

.PhoneInput {
  padding: 0;
  background-color: transparent;
  border: none; }

.PhoneInputCountry {
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 22px !important;
  padding-left: 22px;
  padding-right: 22px;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da; }

input.PhoneInputInput {
  min-width: 237px !important;
  background-clip: padding-box;
  border: 1px solid #ced4da; }

form.col-lg-12.recover.form-whith-eye.form__box {
  align-items: center; }

button.btn.btn-primary.recover__btn {
  max-width: 201px;
  min-width: 201px; }

.dawnload__center {
  align-items: center; }
.second-dawnload-box {
  max-width: 433.15px;
  width: 433.15px;
  margin-top: 45px; }

.user-id-button {
  display: block;
  margin: 0 auto;
  margin-top: 85px !important; }

.configure-mobile {
  &-p {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    &.left {
      text-align: left; } }
  &-ol {
    text-align: left;
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 18px; }
  &-ul {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    margin-top: 20px;
    text-align: left;
    list-style-type: none;
    span {
      word-break: break-all;
      font-family: 'Barlow';
      font-style: normal;
      font-weight: 400;
      font-size: 18px; } }
  &-li-mt-top {
    margin-top: 20px; } }
.loading-img {
  width: 68px;
  height: 68px;
  margin-top: 42px;
  margin-bottom: 31px; }
.inputs-inline {
  margin-top: 20px;
  max-width: 85px;
  input {
    margin-right: 28px;
    padding-left: 0!important;
    padding-right: 0 !important;
    text-align: center; } }

.form__box--recover {
  justify-content: center;
  align-items: center;
  & .recover__sms {
    margin: 0 auto;
    max-width: 348px; } }

.with--arrow--change {
  margin-bottom: 50px !important; }

.btnbox.recover {
  position: relative;
  margin-bottom: 12px;
  .red {
    position: absolute;
    top: 59px;
    font-size: 18px; } }

.form-group.sms {
  margin-bottom: 32px !important; }

.btnbox.sms {
  position: relative;
  margin-bottom: 12px;
  .red {
    position: absolute;
    font-size: 18px;
    white-space: nowrap;
    top: 42px; } }

a.btn.btn-primary.recover__btn {
  max-width: 201px;
  min-width: 201px; }
.recover__header h2 {
  margin-bottom: 48px; }
h2.ta-center {
  margin-bottom: 30px; }
.resend-sms-link {
  text-align: center;
  display: block;
  font-size: 20px;
  line-height: 19px;
  color: #474DDC;
  cursor: pointer;
  &:hover {
    color: darken(#474DDC, 10%); } }
.sms-form .no-margin {
  margin: 0; }
.configure-mobile-p {
  font-size: 22px; }
.configure-mobile-p-red {
  font-size: 22px;
  color:red {} }
.h2-50 {
  font-size: 50px; }
.form-check-input.check {
  min-width: auto !important; }
.form-check.recover__sms.marginblabel.formcheck.margin-0 label {
  font-size: 11px;
  a {
    font-size: 11px !important; } }
form.login__form.login .form-group {
  margin-bottom: 28px; }
form.login__form.login .form__btn {
  margin-top: 30px; }

p.login-p {
  text-align: center;
  margin-top: 65px;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 48px;
  color: #000000; }
p.login-p i {
  margin-left: 15px; }
.recover-account .form__btn {
  margin-top: 0; }
.form-group {
  position: relative; }
.recover__dawnload h4 .img--icon.win {
  width: 46px;
  height: 42px; }
.recover__dawnload {
  display: flex;
  flex-direction: column;
  align-items: center; }
.recover__dawnload h4 .img--icon.ios {
  width: 60px;
  height: 60px;
  right: -22px;
  top: -27px; }
.recover__dawnload h4 .img--icon.win {
  width: 46px;
  height: 42px;
  right: -42px;
  top: -7px; }
.recover__dawnload span {
  margin-bottom: 20px; }
.h2-40 {
  font-size: 40px; }
img.logo_img.second {
  width: 140px;
  height: auto;
  position: relative;
  right: -1px;
  bottom: -7px; }

.qr_box {
  margin-top: 62px; }
.sing_up_box h1 {
  font-size: 46px; }
.recover {
  justify-content: start; }
.p-center {
  max-width: 390px;
  font-size: 18px;
  letter-spacing: 0px;
  line-height: 48px; }

h2.h2-45 {
  font-size: 45px; }
.form-text {
  font-size: 11px; }
label.form-check-label {
  font-size: 11px; }
.formcheck a {
  font-size: 11px !important; }
.form-check-input[type=checkbox] {
  position: relative;
  top: 2px; }
.h2-login {
  font-size: 48px; }
.h2-recover-account {
  font-size: 32px; }
.h2-change-password {
  font-size: 31px; }
.h2-sms-login {
  font-size: 48px; }
.h2-create-password {
  font-size: 43px; }
p.login-p {
  margin-top: -21px; }
.btnbox.login {
  position: relative;
  span.red {
    position: absolute;
    bottom: -45px;
    white-space: nowrap; } }

.PhoneInputCountryIcon.PhoneInputCountryIcon--border {
  background-color: transparent;
  box-shadow: none;
  position: relative;
  top: -7px; }
.PhoneInputCountrySelect:focus + .PhoneInputCountryIcon--border {
  background-color: transparent;
  box-shadow: none !important; }
.btnbox.recover .red {
  top: 39px; }
li.configure-mobile-li-mt-top {
  margin-top: 12px;
  span {
    font-size: 12px; }
  strong {
    font-size: 14px; } }
.configure-mobile-p {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 14px; }
.configure-mobile-p-red {
  font-size: 18px;
  line-height: 18px;
  margin-bottom: 14px;
  color: red; }
.configure-mobile-ol {
  font-size: 15px; }
.configure-mobile-ul {
  font-size: 15px; }

form.login__form.correct_login_form {
  position: static;
  height: 65%;
  margin-top: 36px; }

.btnbox .red.second {
  top: 68px; }

.logout__btn {
  font-family: 'Barlow', sans-serif;
  font-weight: 700 !important;
  font-size: 16px !important;
  position: relative;
  float: right;
  top: 3px;
  right: 40px; }

// MEDIA
// 1444
@media screen and  (max-width: 1444px) {
  .with--arrow svg {
    left: -42px; } }

// 996
@media screen and  (max-width: 997px) {
  .right__image {
    display: none; }
  .login__form__box {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column; }
  form {
    text-align: center;
    .form-group {
      input {
        margin: 0 auto; } }
    button {
      margin: 0 auto;
      font-size: 22px; } }
  .recover__header {
    text-align: center;
    h2,
    h4,
    p {
      text-align: left;
      margin: 0 auto;
      margin-bottom: 25px;
      padding-left: 10px; }
    h2,
    h4 {
      font-size: 32px; }
    p {
      font-size: 20px; } }
  .recover__text span,
  .recover__email span,
  .recover__sms span, {
    text-align: left;
    margin: 0 auto;
    display: block;
    margin-bottom: 10px;
    font-size: 15px; }
  .recover__text input,
  .recover__email input,
  .recover__sms input {
    margin: 0 auto;
    margin-bottom: 35px; }
  .recover__text button,
  .recover__email button,
  .recover__sms button {
    text-align: center;
    margin: 0 auto;
    margin-bottom: 35px;
    font-size: 22px !important; }
  .recover__btn {
    font-size: 22px; }
  a,
  .forgot_pass_link {
    font-size: 16px !important; }
  .flexgroup {
    flex-direction: column;
    input {
      max-width: 348px !important; } }
  .registration__form {
    .form-group {
      margin-bottom: 25px !important; } }
  .form__box .registration__form h2 {
    margin-bottom: 35px; }
  h2 {
    font-size: 32px; }
  .withdots {
    margin-top: 40px; }
  .form__btn {
    margin-top: 22px; }
  .reg-step-two ~ .col-lg-12 {
    .withdots {
      position: relative;
      margin: 0 auto;
      bottom: -40px !important; } }
  .form-check .recover__sms .marginblabel .formcheck {
    max-width: 346px;
    margin: 0 auto;
    margin-bottom: 30px;
    margin-top: 30px; }
  .form-check .form-check-input {
    position: absolute;
    margin-left: -5px; }
  .formcheck label {
    margin-left: 25px;
    font-size: 12px;
    max-width: 330px;
    margin-bottom: 23px; }

  .recover input, .recover select {
    padding: 12px !important;
    margin: 0 auto;
    margin-bottom: 22px; }
  .form__box .login__form input, .form__box .registration__form input {
    padding: 12px !important;
    margin-right: 15px; }
  .recover__sms.marginblabel span {
    margin-top: 15px !important; }
  .form-label {
    font-size: 15px; }
  .recover__sms.marginblabel span {
    margin-bottom: 15px; }
  .form__box .invalid-feedback {
    position: absolute; }
  form.recover__text input {
    margin-bottom: 32px; }
  span.red,
  span.green {
    text-align: center; }
  .form__box .invalid-feedback {
    position: relative;
    height: 0;
    top: auto; }
  form.recover__sms .invalid-feedback {
    position: relative;
    height: 0;
    top: -24px; }
  .with--arrow svg {
    position: relative;
    left: -10px;
    top: 0px; }
  select.form-control.is-invalid + .invalid-feedback {
    top: -3px; }
  .mobile_mt30 {
    margin-top: 30px !important; }
  select.form-control.is-invalid.select__mobile__Error {
    margin-bottom: 0; }
  input.form-control.is-invalid.mobile__with__mb + .invalid-feedback {
    top: -20px; }
  input.form-control.is-invalid.mobile__without__mb + .invalid-feedback {
    top: 0; }
  .col-lg-6.recover form.recover__text .invalid-feedback {
    top: -31px; }
  .recover__sms {
    text-align: left; }
  .recover__sms.marginblabel span {
    margin-bottom: 5px;
    margin-top: 20px; }
  form.col-lg-6.recover.newpass .invalid-feedback {
    top: -18px; }
  .recover__dawnload {
    display: flex;
    flex-direction: column;
    align-items: center;
 }    // margin-bottom: 32px
  .recover__header.dawnload h2 {
    text-align: center !important; }
  .recover__dawnload h4 .img--icon {
    position: absolute;
    right: -62px;
    top: 25px; }
  .litle__input__error {
    max-width: 100%; }
  .max__w {
    text-align: left;
    margin: 0 auto;
    margin-bottom: 25px;
    top: -20px; }
  .with__eye {
    margin: 0 auto; }
  .with__eye .pass__eye {
    top: 15px; }
  .wrapper header .logo img {
    width: 89px;
    height: 51px; }
  .recover__sms.marginblabel.lastselect .invalid-feedback {
    top: -17px; }
  label.form-check-label {
    text-align: left;
    margin: 0 auto; }
  .form-check.recover__sms.marginblabel.formcheck {
    max-width: 354px;
    margin: 0 auto;
    margin-top: 18px; }
  .form-check .form-check-input {
    position: static;
    margin-left: -19px;
    margin-right: 5px;
    margin-top: 4px;
    width: 5px;
    height: 5px;
    -webkit-appearance: none;
    appearance: none;
    padding: 7px !important; }
  .formcheck label {
    font-size: 7px; }
  .dot.dot-lg {
    margin: 0 10px;
    height: 20px;
    width: 20px; }
  .dot {
    border: 2px solid #000; }
  h2 {
    text-align: center !important; }
  .wrapper .container form.col-lg-6.recover > div {
    margin: 0 auto; }
  .col-lg-6.recover > div {
    min-width: 348px;
    margin: 0 auto; }
  form.select__form {
    margin: 0 auto; }
  form.registration__form > div {
    margin: 0 auto;
    min-width: 348px; }
  form.registration__form {
    text-align: left; }
  form.login__form {
    text-align: left;
    margin: 0 auto;
    max-width: 348px; }
  .recover__header {
    h2 {
      text-align: left;
      margin: 0 auto;
      margin-bottom: 25px;
      padding-left: 0; } }
  form.registration__form {
    margin: 0 auto;
    max-width: 348px; }
  form.col-lg-6.recover.form-whith-eye .form-check.recover__sms.marginblabel.formcheck {
    margin-top: 25px; }
  .invalid-feedback.pass_two_error.form-whith-eye {
    text-align: left;
    margin-top: 20px; }
  .invalid-feedback {
    text-align: left; }
  .span-whith-code {
    text-align: center; }
  .recover__email.to-center span {
    text-align: left; }
  span.span-whith-code {
    text-align: center; }

  .sing_up_box h1 {
    font-size: 32px; }
  .qr_box {
    padding: 0;
    margin: 40px 0;
    margin-top: 0; }
  .invalid-feedback.recover {
    top: -17px; }
  .btnbox.sms .red {
    top: 60px; }
  p.login-p {
    display: none; }
  .with__eye {
    margin: 0 auto;
    margin-left: 0; }
  .form__box .login__form .form-check-input.check {
    padding: 8px !important; }
  h2.h2-45 {
    font-size: 32px;
    padding-left: 0;
    margin-left: -14px; }
  .form__center p.p-center {
    max-width: 277px !important;
    font-size: 15px;
    line-height: 35px; }
  .user-id-button {
    margin-top: 74px !important; }
  p.p-center.first {
    font-size: 21px; }
  .h2-50 {
    font-size: 46px; }
  .recover__header h2.h2-create-password {
    font-size: 42px; }
  .formcheck label {
    font-size: 11px; }
  .btnbox.login {
    span.red {
      position: static;
      white-space: normal; } } }

// 620
@media screen and  (max-width: 620px) {
  .sing_up_box {
    padding-left: 0; }
  .sing_up_box {
    width: 100%; }
  h1, h2, .h2-login, .h2-sms-login, .h2-create-password {
    font-size: 32px;
    letter-spacing: 1px; }
  .recover__sms {
    max-width: 262.7px !important;
    margin: 0 auto; }
  .recover {
    justify-content: space-around; }
  .form__center p.p-center {
    max-width: 248px;
    margin: 0 auto;
    margin-bottom: 1rem; }
  .form-group {
    max-width: 346px; }
  p.mobile__text {
    max-width: 249px;
    line-height: 35px; }
  .download-br {
    margin-bottom: 7px;
    display: block; }
  a.download-link {
    color: #0845E1 !important;
    margin-top: 33px;
    display: block;
    font-weight: 600; }
  img.mobile__image {
    margin-top: -45px; }
  .form-group {
    max-width: 262.71px; }
  input.form-control {
    min-width: 262.71px !important;
    max-width: 262.71px !important; }
  .form-group.inputs {
    max-width: 320.71px; }
  .inputs-box .inputs-inline input {
    min-width: auto !important;
    max-width: 40px !important; }
  .PhoneInputCountry {
    margin-right: 11px !important; }
  input.PhoneInputInput {
    min-width: 168px !important; }
  .form__box .login__form .form-group, .form__box .registration__form .form-group, .form-group {
    margin-bottom: 32px; }
  .form__btn {
    margin-top: 80px; }
  .two-forms .form__btn {
    margin-top: 10px; }
  .recover-psw .recover__sms.marginblabel.with-margin-bottom {
    margin-bottom: 25px; }
  .form__btn {
    padding: 5px; }
  .form-group-code {
    margin: 0 auto; }
  .resend-sms-link {
    font-size: 15px; }
  .btnbox.sms.bottom button {
    margin-top: 42px; }
  .recover__btn {
    padding: 5px; }
  .configure-mobile-p {
    font-size: 15px;
    margin-bottom: 4px; }
  .configure-mobile-p-red {
    font-size: 15px;
    margin-bottom: 4px;
    color: red; }
  .qr_box {
    margin-top: 48px; }
  // img.logo_img.first
  //   height: 30.15px !important
  // img.logo_img.second
  //   width: 114px !important
  //   right: 8px
  //   bottom: -23px
  button.btn.btn-primary.form__btn.signup-btn {
    margin-top: 48px; }
  p.configure-mobile-p.left {
    text-align: center;
    margin-right: 8px;
    font-size: 15px;
    margin-bottom: 12px; }
  ol.configure-mobile-ol li {
    font-size: 15px;
    margin-bottom: 12px;
    line-height: 18.6px; }
  ul.configure-mobile-ul li {
    font-size: 15px;
    line-height: 18.6px; }
  .configure-mobile-ul span {
    font-size: 15px; }
  button.btn.btn-primary.form__btn.margin-0 {
    margin-top: -5px; }
  .sing_up_box {
    padding: 0; }
  li.configure-mobile-li-mt-top {
    margin-right: 25px; }
  button.btn.btn-primary.form__btn.margin-5 {
    margin-top: -5px; }
  button.btn.btn-primary.form__btn.margin-20 {
    margin-top: 20px; }
  img.loading-img {
    margin-bottom: 52px; }
  .form__box .login__form .inputs-box input, .form__box .registration__form .inputs-box input {
    height: 64px; }
  .inputs-box {
    width: auto; }
  .h2-50 {
    font-size: 32px; }
  .formcheck label {
    font-size: 11px; }
  .recover__header h2.h2-create-password {
    font-size: 32px; }
  .wrapper {
    overflow-x: hidden; }
  //   overflow-y: hidden
  h2.h2-mobile-dawnload {
    font-size: 25px; }
  // .form-check.recover__sms.marginblabel.formcheck.margin-0 label
  //   font-size: 8px
  // .form-check.recover__sms.marginblabel.formcheck.margin-0 label a
  //   font-size: 8px !important
  .btnbox.recover .red {
    font-size: 14px; }
  .sing_up_box h1.big-page {
    margin-bottom: 20px; }
  p.configure-mobile-p.left {
    font-size: 11px;
    margin-bottom: 5px; }
  ol.configure-mobile-ol li {
    font-size: 12px;
    margin-bottom: 1px;
    line-height: 16px; }
  ul.configure-mobile-ul li {
    font-size: 12px;
    line-height: 16px; }
  button.btn.btn-primary.form__btn.margin-5 {
    margin-top: 10px; }
  .container.with__dots .row.login__page__row.mobile-width {
    min-height: 540px; }
  .btnbox.sms .red.first {
    top: 23px;
    font-size: 14px; }
  .btnbox.sms .red.second {
    top: 68px;
    font-size: 14px; } }
